.loader {
  animation: spin 1.5s ease-in-out infinite;
  margin: 50px auto;
  border: 5px double #f0eff5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
    border-top:5px dashed white;
    border-bottom:5px dashed white;
  }
  25% {
    border-top:5px dashed white;
    border-bottom:5px dashed white;
  }
  50% {
    border-top:5px dashed white;
    border-bottom:5px dashed white;
  }
  75% {
    border-top:5px dashed white;
    border-bottom:5px dashed white;
  }
  100% {
    border-top:5px dashed white;
    border-bottom:5px dashed white;
  }
}