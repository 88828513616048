.page__container {
  min-width: 320px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 45px 0 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media screen and (max-width: 414px) {
  .page__container {
    padding: 28px 0 36px;
  }
}