.form__items {
  margin: 0 0 28px;
  padding: 0;
  border: 0;
}

@media screen and (max-width: 414px) {
  .form__items {
    margin: 0 0 45px;
  }
}