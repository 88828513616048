.header__navlink {
  align-content: center;
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  
  transition: opacity .5s ease;
}

.header__navlink:hover {
  opacity: 0.6;
}

@media screen and (max-width: 414px) {
  .header__navlink {
    font-size: 14px;
    margin: 0 30px 0 0;
  }
}
