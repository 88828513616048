.profile__add-btn {
  width: 150px;
  height: 50px;
  margin-left: auto;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 2px;
  outline: none;
  background: url(../../../images/add-icon.svg) no-repeat 50%;
  align-self: center;
  cursor: pointer;
  transition: opacity .5s ease;
}

.profile__add-btn:hover {
  opacity: .6;
}

@media screen and (max-width: 1023px) {
  .profile__add-btn {
    width: 282px;
    margin: 0;
  }
}

@media screen and (max-width: 414px) {
  .profile__add-btn {
    background-size: 16px;
  }
}
