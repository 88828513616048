.profile__bio {
  max-width: 338px;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .profile__bio {
    font-size: 16px;
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 414px) {
  .profile__bio {
    font-size: 14px;
  }
}