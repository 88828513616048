.profile__avatar-container {
  position: relative;
  margin: 0;
  border-radius: 50%;
}

.profile__avatar-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1023px) {
  .profile__avatar-container {
    margin: 0 0 27px 0;
  }
}