.profile__edit-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: 1px solid #fff;
  outline: none;
  background: url(../../../images/edit-icon.svg) no-repeat 50%;
  grid-column: -1 / -2;
  grid-row: 1 / 2;
  cursor: pointer;
  transition: opacity .5s ease;
}

.profile__edit-btn:hover {
  opacity: .6;
}

@media screen and (max-width: 414px) {
  .profile__edit-btn {
    width: 18px;
    height: 18px;
    background-size: 8px;
  }
}