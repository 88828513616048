.element__delete-btn {
  width: 18px;
  height: 19.3px;
  padding: 0;
  border: 0;
  outline: 0;
  background: url(../../../images/delete-icon.svg) no-repeat 50%;
  position: absolute;
  top: 18px;
  right: 15px;
  cursor: pointer;
  transition: opacity .5s ease;
}

.element__delete-btn:hover {
  opacity: .6;
}