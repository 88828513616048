.page__footer {
  width: 68.75%;
  margin: 0 auto;
  padding: 0;
}

@media screen and (max-width: 1023px) {
  .page__footer {
    width: calc(100% - 144px);
  }
}

@media screen and (max-width: 845px) {
  .page__footer {
    width: calc(100% - 38px);
  }
}