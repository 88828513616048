.popup__close-btn {
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  background: url(../../../images/close-icon.svg) no-repeat 50%;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
  transition: opacity .5s ease;
}

.popup__close-btn:hover {
  opacity: .6;
}

@media screen and (max-width: 618px) {
  .popup__close-btn {
    top: -59px;
    right: 5px;
  }
}

@media screen and (max-width: 414px) {
  .popup__close-btn {
    background-size: 38px;
    width: 26px;
    height: 26px;
    top: -45px;
    right: 5px;
  }
}

@media screen and (max-width: 823px) and (max-height: 414px) {
  .popup__close-btn {
    top: 0;
    right: -45px;
  }
}