.profile__name {
  max-width: 338px;
  padding: 0;
  margin: 0;
  font-size: 42px;
  line-height: 1.15;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .profile__name {
    font-size: 36px;
  }
}

@media screen and (max-width: 414px) {
  .profile__name {
    font-size: 27px;
  }
}