.profile__avatar {
  width: 120px;
  height: 120px;
  margin: 0 30px 0 0;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1023px) {
  .profile__avatar{
    margin: 0;
  }
}