.popup__container_type_form {
  width: 430px;
  border-radius: 10px;
  padding: 34px 36px 37px;
  color: #000;
  background-color: #fff;
}

@media screen and (max-width: 618px) {
  .popup__container_type_form {
    width: 376px;
  }
}

@media screen and (max-width: 414px) {
  .popup__container_type_form {
    width: calc(100% - 38px);
    padding: 25px 22px;
  }
}

@media screen and (max-height: 320px) {
  .popup__container_type_form {
    padding: 20px 36px;
  }
}