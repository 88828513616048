.header__button {
  margin: 0 0 0 24px;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #A9A9A9;
  background-color: inherit;
  border: none;
  cursor: pointer;

  transition: opacity .5s ease;
}

.header__button:hover {
  opacity: 0.6;
}
