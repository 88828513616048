.header__user-info {
    display: flex;
    flex-wrap: wrap;
  }
  
  @media screen and (max-width: 1024px) {
    .header__user-info {
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
    }
  }
  
  @media screen and (max-width: 414px) {
    .header__user-info {
        margin-right: 30px;
    }
  }
  