.header__logo {
  width: 142px;
  margin-right: 20px;
}

@media screen and (max-width: 414px) {
  .header__logo {
    width: 103.75px;
    margin-left: 30px;
  }
}