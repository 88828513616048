.page {
  background-color: #000;
  color: #fff;
  margin: 0;
  font-family: 'Inter', "Arial", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  min-height: 100vh;
}