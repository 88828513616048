.popup__message {
  margin: 0;
  font-size: 24px;
  line-height: 1.21;
  font-weight: 900;
  text-align: center;
}

@media screen and (max-width: 414px) {
  .popup__message {
    font-size: 20px;
  }
}
