.profile__avatar-btn {
  padding: 0;
  border: 0;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .2s ease;
}

.profile__avatar-btn:hover,
.profile__avatar-btn:focus {
  background-image: url(../../../images/edit-icon.svg);
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,.8);
  
}