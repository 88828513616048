.form__heading {
  margin: 0 0 39px;
  font-size: 24px;
  line-height: 1.21;
  font-weight: 900;
}

@media screen and (max-width: 618px) {
  .form__heading {
    font-size: 23px;
  }
}

@media screen and (max-width: 414px) {
  .form__heading {
    font-size: 18px;
    margin: 0 0 65px;
  }
}