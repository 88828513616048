.form__submit-btn {
  width: 358px;
  height: 50px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  line-height: 1.22;
  cursor: pointer;
  transition: opacity .3s ease;
}

.form__submit-btn:hover {
  opacity: .8;
}

@media screen and (max-width: 619px) {
  .form__submit-btn:not(.form__submit-btn_theme_dark) {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .form__submit-btn_theme_dark {
    width: calc(100% - 60px);
    height: 46px;
    font-size: 14px;
    line-height: 1.21;
  }
}
