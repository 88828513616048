.popup__container_type_message {
  width: 430px;
  border-radius: 10px;
  padding: 60px 36px;
  color: #000;
  background-color: #fff;
}

@media screen and (max-width: 618px){
  .popup__container_type_message {
    width: 376px;
  }
}

@media screen and (max-width: 414px) {
  .popup__container_type_message {
    width: calc(100% - 38px);
    padding: 50px 22px;
  }
}
