.form_theme_dark {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 60px 0;
}

@media screen and (max-width: 414px) {
  .form_theme_dark {
    padding: 40px 0;
  }
}
