.footer__copyright {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.2;
  color: #545454;
}

@media screen and (max-width: 414px) {
  .footer__copyright {
    font-size: 14px;
  }
}